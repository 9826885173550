.custom-table{
    margin: 0;
    height: 3.8rem;  
}
.table > :not(:first-child) {
    border-top: 1px solid #e8eaf6;
}
tbody, td, tfoot, th, thead, tr {
    border-style: none;    
}


/* pagination */
.pagination {
    margin: 15px auto;
    display: flex;
    outline: none;
    justify-content: end;
  }
  .pagination > .active > a{
    background-color: #004C97 ;
    border-color: #004C97 ;
    color: white;
    border-radius: 4px;
  }
  .pagination > li > a{
    /* border: 1px solid #004C97 ; */
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #004C97 ;
    border-color: #004C97;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #004C97
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset;
    font-weight: bold;
    
  }
  
.pagination > .active > a:hover {    
    color: white;
}

.rowLog{
  max-width: 100vw;
  max-height: 40px !important;
  display: table-row;
    text-wrap: nowrap;
}

.tableLog{
  max-width: 100vw;
  width: 100vw;
  overflow: scroll;
}

.contentCell{
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}