.react-calendar-title{
    padding-left: 0.75rem;
    font-size: 20px;
    color: #333333;
}

/* Comportamiento al hacer clic o estan seleccionadas */
.disable-react-calendar{
    background-color: #F0F0F0;
    border: 1px;
    border-radius: 12px;
    color: #BDBDBD;
}

.disable-react-calendar.react-calendar__tile--active{
    background-color: #F0F0F0;
    color: #BDBDBD;
}

.disable-react-calendar.react-calendar__tile--active:enabled:focus{
    background-color: #F0F0F0;
}

.disable-react-calendar.react-calendar__tile:enabled:focus{
    background-color: #F0F0F0;
}

/* Comportamiento cuando no estan seleccionadas */
.active-react-calendar{
    background-color: transparent;
    border: 1px;
    border-radius: 12px;
}

.active-react-calendar.react-calendar__tile--active{
    background-color: #1351A5;
}

.active-react-calendar.react-calendar__tile--active:enabled:focus{
    background-color: #1351A5;
}

/* Modificaciones CSS del componente directamente */
.react-calendar__tile--active{
    background-color: #1351A5;
    color: white;
} 

.react-calendar__tile--active:enabled:hover{
    background-color: #1351A5;
}

.react-calendar__tile:enabled:hover{
    background-color: #1351A5;
    color: #FFFF;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
    border: 1px;
    border-radius: 12px;
}

.react-calendar__tile.disable-react-calendar:hover{
    background-color: #F0F0F0;
    color: #BDBDBD;
    cursor: not-allowed !important;
}

.react-calendar{
    border: 0;
    width: 250px;
    
}

.react-calendar__month-view__weekdays{
    color: #BDBDBD;
}


/* DIAS FESTIVOS PAGE */
.dias-festivos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
}

.dias-festivos-button-hoy{
    margin-left: 2rem;
    border: 1px solid #BDBDBD;
    background-color: #FFFFFF;
    border-radius: 14px;
    padding: .5rem 1rem;
}

.dias-festivos-button-hoy:hover{
    background-color: #1351A5;
    color: #FFFFFF ;
}

.dias-festivos-titulo-container{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.dias-festivos-button-cog{
    display: flex;
    align-self: flex-end;
    margin-right: 1rem;
    cursor: pointer;
} 

.dias-festivos-button{
    width: 7rem;
} 


/* Modal de la pagina */

.dias-festivos-modal-icon{
    border: 0;
    border-radius: 25px;
    padding: .25rem;
    margin: .25rem;
    justify-content: center;
}

.dias-festivos-modal-icon-days{
    border: 0;
    border-radius: 25px;
    padding: .25rem;
    margin: .25rem;
    height: 32px;
    width: 32px;
    justify-content: center;
    cursor: pointer;
}

.dias-festivos-modal-icon-days.active-day{
    background-color: #1351A5;
    color: #F5F5F5;
}


.dias-festivos-modal-icon-days.inactive-day{
    background-color: #F5F5F5;
    color: #333333;
}

.dias-festivos-modal-underline{
    padding-bottom: .5rem;
    border-bottom: 2px solid #F5F5F5;
}

.dias-festivos-modal-smalltext{
    font-size: .75rem;
    color: #9F9D9D;
}