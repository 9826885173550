  /* ProgressBar.css */
  .progress-bar-container {
    z-index: 0;
    width: 85%;
    background: repeating-linear-gradient(to right,
        #c8c8c8,
        /* Line color */
        #c8c8c8 4px,
        /* Line width */
        #fff 4px,
        /* Gap between lines */
        #fff 8px
        /* Line width + gap */
      );
    border-radius: 4px;
    position: relative;
    margin: .5rem;
    margin-top: 70px;
  }

  .progress-bar-fill {
    z-index: 2;
    height: 30px;
    background-color: #fff;
    position: relative;
  }

  .progress-bar-lines {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    /* Makes sure the lines don’t interfere with mouse events */
    /* pointer-events: none; */
  }

  .progress-bar-lines.primary {
    background: repeating-linear-gradient(to right,
        #55BEFF,
        /* Line color */
        #55BEFF 4px,
        /* Line width */
        #fff 4px,
        /* Gap between lines */
        #fff 8px
        /* Line width + gap */
      );
  }

  .progress-bar-lines.secondary {
    background: repeating-linear-gradient(to right,
        #1151A5,
        /* Line color */
        #1151A5 4px,
        /* Line width */
        #fff 4px,
        /* Gap between lines */
        #fff 8px
        /* Line width + gap */
      );
  }

  .progress-secondbar {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 30px;
  }

  .progress-bar-negative {
    z-index: 1;
    background: repeating-linear-gradient(to right,
        black,
        black 4px,
        transparent 4px,
        transparent 8px);
  }

  /* .progress-bar-negative:hover {
    cursor:copy;
  } */

  .progress-bar-tooltip-top {
    visibility: hidden;
    color: #fff;
    font-size: 13px;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 130%; 
    margin-left: -75px; 
    opacity: 0;
    transition: opacity 0.3s;
}

.progress-bar-tooltip-top.primary{
  background-color: #55BEFF;
}

.progress-bar-tooltip-top.secondary{
  background-color: #1151A5;
}

/* .progress-bar-fill:hover {
  cursor: pointer;
} */

.progress-bar-fill:hover + .progress-bar-tooltip-top {
  visibility: visible;
  opacity: 1;
}

.progress-bar-tooltip-top::after {
  content: '';
  position: absolute;
  top: 100%; 
  left: 50%;
  margin-left: -5px; 
  border-width: 10px;
  border-style: solid;
}

.progress-bar-tooltip-top.primary::after {
  border-color: #55BEFF transparent transparent transparent; 
}

.progress-bar-tooltip-top.secondary::after {
  border-color: #1151A5 transparent transparent transparent; /* Arrow color */
}

.progress-bar-tooltip-bottom {
  visibility: hidden;
  min-width: 90px;
  background-color: #333;
  color: #fff;
  font-size: 13px;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 130%; /* Position the tooltip above the container */
  margin-left: -75px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.progress-bar-negative:hover + .progress-bar-tooltip-bottom {
  visibility: visible;
  opacity: 1;
}

.progress-bar-tooltip-bottom::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px; /* Center the arrow */
  border-width: 10px;
  border-style: solid;
  border-color: #333 transparent transparent transparent; /* Arrow color */
}