.tooltipUI {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 4px;
    z-index: 9999;
    min-width: 200px !important;
    width: auto !important;
    max-width: 500px;
}

.tooltipComentarios {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 4px;
    z-index: 9999;
    min-width: 160px !important;
    width: auto !important;
    max-width: 500px;
}