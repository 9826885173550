.programacionJobsCarteraEditButton{
    display: flex;
    align-items: center;
    background: #e9ecef;
    border-radius: 11px;
    text-align: center;
}

.programacionJobsCarteraModal{
    max-width: 30rem;
}

.programacionJobsCarteraModalSelect{
    background: #f5f5f5 ;
    border: none ;
    color: #444444;
    padding: .5rem;
    border-radius: 6px;
    /* margin-bottom: 1rem; */
}

