:root{
    --back-color: #f5f5f5;
    --primary-color: #FFFEFE;
}
body {
    font-family: 'Gotham Light', sans-serif;
}
.btn{
    background: #004C97;
    border-style: none;
    color: #fff;
}
.contenedor{   
    background: #f5f5f5;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;    
    /* height: 100%; */
}
.container-loginpassword{
    background: #FFFEFE;    
    padding: 55px;
    border-radius: 5px;
    z-index: 1;
}
.container-login{
    background: #FFFEFE;    
    padding: 80px;
    border-radius: 5px;
    z-index: 1;
}
.btn:hover{
    background: 1351a5;
    border-style: none;
    color: #fff;
}
.logo{
    width: 6em;
}
.logo-container{
    text-align: center;
    margin-bottom: 25px;
}
input[type=checkbox]:checked {
    background-color: #004C97 !important;
}
/* input[type=checkbox]:checked{
    background-color: #1351a5 !important;
} */
.remenber-pass{
    text-decoration:underline;
}
.mb-4, .my-4 {
    margin-bottom: .5rem!important;
}
/* input login */
.inner-addon {
    position: relative;
}
.inner-addon .glyphicon {
position: absolute;
padding: 10px 10px;
pointer-events: none;
}
.inner-addon .glyphiconField {
    position: absolute;
    padding: 6px 10px;
    pointer-events: none;
    }
.right-addon input {
padding-left: 32px;
}
.left-addon input {
padding-left: 32px;
}
/* admin style */
.logo-head{
    width: 8em;
    padding: 0px;
    margin: 0px;
}
.logo-head figure{
    margin: 0px;
}
.navbar{
    background: var(--primary-color);
}
.main .menu{
    background: red;
}
.form-control{
    background:#f5f5f5;
    border: none;
}

/* Footer */

footer{
    background: #004C97;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;   
    height: 20rem;
}
footer h5{  
    font-size: 12px;
}
footer.fixed{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

/* media query */
@media (max-width: 570px){
    .container-login{
        height: 100vh;
    }
    .container-loginpassword{
        height: 100vh;
    }
}
input.input-error{
border-color: crimson;
}