.estadistica-proceso-bar{
    height: 290px;
    padding: 1rem;
    margin: 1rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border: 1px solid #ECECEC;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}

.seguimiennto-telfonico-busqueda{
    display: flex;
    align-items: center;
    justify-content: center;
}

.seguimiennto-telfonico-calendar {
    font-size: 13px;
    background: #F5F5F5;
    color: #616161;
    padding: .75rem .5rem;
    margin: .25rem;
    border-radius: 5px;
}

.seguimiennto-telfonico-busqueda-bar{
    width: 100%;
}

.estadistica-promesas-de-pago{
    height: 290px;
    padding: 1rem;
    margin: 1rem;
    border: 1px solid #ECECEC;
}


.estadistica-promesas-de-pago-vigentes{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: .5rem;
}

.estadistica-monto-final{
    height: 137px;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 1rem;
    margin: 1rem;
    border: 1px solid #ECECEC;
}

.estadistica-monto-final-texto{
    color: #CE2223;
    font-size: 20px;
}

.estadistica-monto-cobrado-texto{
    color: #47A067;
    font-size: 20px;
}

.estadistica-monto-final-numero{
    color: #CE2223;
    font-size: 25px;
}

.estadistica-monto-cobrado-numero{
    color: #47A067;
    font-size: 25px;
}

.seguimiento-telefonico-dropdown {
    position: relative;
    display: inline-block;
}

.seguimiento-telefonico-dropdown-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.seguimiento-telefonico-dropdown-content {
    display: block;
    position: absolute;
    right: 100%;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Style the links inside the dropdown */
.seguimiento-telefonico-dropdown-content-link  {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of links on hover */
.seguimiento-telefonico-dropdown-content-link:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}


.seguimiennto-telfonico-promesa-pago-dias-hoy {
    background: #1251A5;
    color: white;
    padding: .25rem 0.75rem;
    margin: .25rem;
    border-radius: 5px;
}

.seguimiennto-telfonico-promesa-pago-dias.active {
    background: #333333;
    color: white;
    padding: .25rem 0.75rem;
    margin: .25rem;
    border-radius: 5px;
    cursor: pointer;
}

.seguimiennto-telfonico-promesa-pago-dias.inactive {
    background: #c8c8c8;
    color: #333333;
    padding: .25rem 0.75rem;
    margin: .25rem;
    border-radius: 5px;
    cursor: pointer;
}

.unidades-remate-icon {
    background: #ededed;
    color: #333333;
    border-radius: 20px;
}

.seguimiento-telefonico-detalle-campo-title{
    margin-top: 1rem;
    padding: 10px 0px;
    /* border-bottom: 2px solid gray; */
    min-height: 60px;
}
  
  .seguimiento-telefonico-detalle-position-badge {
    position: relative;
    bottom: 10%;
    left: 25%;
    transform: translate(50%, -50%);
  }

  .seguimiento-telefonico-detalle-background {
    margin: 1rem;
    background-color: white;
  }

  .seguimiento-telefonico-detalle-border-bottom-custom {
    position: relative;
    padding-bottom: 10px; /* Adjust space for the border */
}
.seguimiento-telefonico-detalle-border-bottom-custom::after {
    content: "";
    position: absolute;
    bottom: 0;
    /* left: 10%; */
    width: 90%;
    border-bottom: 2px solid #E0E0E0; /* Customize border color and width */
}

.seguimiento-telefonico-detalle-nombre{
    background: #E8EAF6;
    border-radius: 5px;
    padding: .25rem .5rem;
    margin-left: .25rem;
}

.seguimiento-telefonico-detalle-nav-link{
    display: block;
    padding: .5rem 1rem;
    color: #333333;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;

}

.seguimiento-telefonico-detalle-nav-link:active{
    border-bottom: 2px solid #004C97;
}

.seguimiento-telefonico-detalle-nav-link:hover{
    border-color: transparent transparent #004C97 transparent;
}

.seguimiento-telefonico-detalle-nav-link.tab-active {
    border-bottom: 2px solid #004C97;
  }
 
  .small-input {
    width: 300px; /* Ajusta el ancho a tu preferencia */
 
    font-size: 14px; /* Opcional: ajusta el tamaño de fuente */
}
  