.table-collapsable
{
    border: solid;
    border-width: thin;
    border-color: #E0E0E0;
}

.stripped.odd
{
    background-color: #FFF;
}
.stripped.even
{
    background-color: #f5f5f5;
}

.table-collapsable .btn-link
{
    border: none;
    height: 10px;
    text-decoration: none;
    background-color: transparent;
}

.table-collapsable .btn-link:hover
{
    color: #09397e;
}