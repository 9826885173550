.btnEdit {
  display: none;
}

.btnDetail {
  display: none;
}

.btnActions {
  display: none;
}

.btnDelete {
  display: none;
}

tbody tr:hover {
  border: 1px solid #004C97;

  // box-shadow: 0px 1.4px 8px .5px rgba(0,76,151,0.52);
  .btnEdit {
    display: block;
  }

  .btnDetail {
    display: block;
  }

  .btnActions {
    display: block;
  }

  .btnDelete {
    display: block;
  }
}

.dropdown-toggle::after {
  content: none;
}

.btn-tbl {
  border: none;
  background-color: #004c97;
  color: white;
  border-radius: 3px;
}

.btn-tbl-circle {
  border: none;
  color: white;
  border-radius: 20px;
}

.btn-tbl-circle-st {
  border: none;
  background-color: #004c97;
  color: white;
  border-radius: 20px;

}

.btn-tbl-circle:hover {
  background-color: #004c97;

  .ico {
    color: white !important;
  }
}

.active-color {
  background-color: #004c97;
}

.none-color {
  background-color: #e8eaf6;
}

// section-input
.section-file {
  height: 128px;
  border: 2px dashed gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-up {
  font-size: 40px;
  color: gray;
}

/* test */
@media (min-width: 1200px) {
  .toggle-sidebar-aside {
    width: 104px;
  }
 
  .Visible {
    margin-left: 300px !important;
  }

  .noVisible {
    margin-left: 104px !important;
  }
}

@media (max-width: 1199px) {
  .icon_burger {
    display: none;
  }
}
@media (min-width: 1200px) {
  .toggle-sidebar .sidebar {
      left: 0px;
  }
 
}