.modal.comments{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    display: none;
    justify-content: end;
    align-items: end;
    padding-right: 11rem;
    padding-bottom: 4rem;
}
.modal.is-open.comments{
    display: flex;
}
.modal-container.comments{
    position: absolute;
    padding: 1rem;
    min-width: 320px;
    border: 1px solid black;
    /* max-width: 600px; */
    /* min-height: 200px;
    max-height: 600px;
    overflow: auto; */
    /* background-color: #fff; */
    border-radius: 5px;
}
.modal-close.comments{
    position: absolute;
    top: 1rem;
    right: 1rem;
}