/* HalfCircleProgressBar.css */
/* /////////////////nuevo intento */

.gauge{
    width: 100%;
    max-width: 325px;
    font-size: 32px;
    color: #1151A5 ;
}

.gauge_text_small{
    font-size: 16px;
}

.gauge_body{
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background: #c8c8c8; /*Color de no completado*/
    position: relative;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    overflow: hidden;

}

.gauge_fill{
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: 100%;
    background: #1151A5;
    transform-origin: center top ;
    transform: rotate(0.25turn);
    transition: transform 0.2s ease-out;
}

.gauge_cover{
    width: 75%;
    height: 125%;
    /* background: #fff; */
    border-radius: 50%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%);


    /*Text*/
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25%;
    box-sizing: border-box;
}

.gauge_sub_cover{
    width: 75%;
    height: 170%;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%);


    /*Text*/
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25%;
    box-sizing: border-box;
    font-size: 1rem;
}


