:root{
    --bar-color: #004c97;
    --fill-size:0%;
}

.loaderBar-background {
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    height: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
    /* Centramos vertical y horizontalmente */
}

.loaderBar {
    height: 100%;
    width: 0;
    background-color: #004c97;
    border-radius: 5px;
    animation: fillAnimation 50s ease-in-out infinite;
}

.messageBar {
    color: white;
    position: relative;
    top: 140px;
    left: 35px;
}

@keyframes fillAnimation {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
} 