.wrapper-statistics
{
    border: solid;
    border-width: thin;
    border-radius: 3px;
    border-color: #D5D5D5;
}

.wrapper-statistics .title
{
    color: #b9b9b9;
    font-size: 13px;
}

.wrapper-statistics .not-assigned
{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FAC4C4;
    color: #CE2222;
}

.wrapper-statistics .assigned
{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #D8F2CD;
    color: #47A066;
}

