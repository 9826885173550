.texto-cuadro{
    display: flex;
    margin-top: .5rem;
}

.cuadro-clave{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: #E8EAF6;
    border-radius: 3px;
    margin-left: 2px;
    color: #004C97;
}

.cuadro-active{
    background-color: #E8EAF6;
    color: #004C97;
}

.cuadro-inactive{
    background-color: #F0F0F0;
    color: #616161;
}