.modal-comments{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    display: none;
    justify-content: end;
    align-items: center;
}
.modal-comments.is-open{
    display: flex;
}
.modal-comments-container{
    position: relative;
    padding: 1rem;
    min-width: 320px;
    overflow-y: auto;
    border-radius: 5px;
}
.modal-close{
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.comments-header
{
    height: 30px;
}

.comments-content
{
    height: calc(94vh - 80px); /* Cambia este valor a lo que necesites */
    overflow-y: auto; /* Permite el desplazamiento vertical */
}

.comments-content.bg-gray
{
    background-color: #F7F7F7;
}

.comments-content.bg-white
{
    background-color: #FFFFFF;
}

.icon-no-comments
{
    color: #1A3D7E;
    font-size: 48px;
}

.comments-content .content-date
{
    font-size: 13px;
    color: #707070;
}

.comments-bar
{
    height: 30px;
}

.comment
{
    background-color: #FFF;
    min-height: 100px;
    height: auto;
    border: solid;
    border-color: #e0e0e0;
    border-width: 0px 0px 1px 0px;
}

.comment .avatar
{
    background-color: #1A3D7E;
    color: #FFF;
    width: 35px !important;
    height: 35px !important;
    display: block;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    padding: 2px;
    font-size: 13px;
    text-align: center;
}

.comment .folio
{
    font-size: 12px !important;
}

.comment .comment-content
{
    font-size: 13px;
}

.btn-send
{
    width: 33px !important;
    height: 33px !important;
    text-align: center;
    justify-content: center;
    font-size: 14px;
}

.btn-send:hover
{
    background-color: #183a7a;
}